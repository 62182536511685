import {
  IProduct,
  IProductsAndVideosTrackingParams,
  IPromotion,
  ITrackingParams,
} from 'src/explore/types/shoppe';

const syncProductAndVideoDataToPage = (
  trackingData: ITrackingParams,
  page: Array<IProduct | IPromotion>
) => {
  if ( !trackingData || !trackingData.products_and_videos ) return trackingData;

  const productsAndVideosSorted = page.map(( item: IProduct | IPromotion ) => {
    if ( 'image' in item ) return null; // IPromotion

    const metaData = trackingData.products_and_videos[item.id];
    const itemData: IProductsAndVideosTrackingParams = {
      merchant_id: Number( item.merchant?.id ),
      product_id: Number( item.id ),
      version: metaData ? metaData.version : null,
    };
    if ( item.tvVideo ) {
      itemData.video_id = String( item.tvVideo.id );
      itemData.video_displayed = !!item.showGbVideo;
    }
    return itemData;
  });

  return {
    ...trackingData,
    products_and_videos: productsAndVideosSorted,
  };
};

export const SearchTracking = { syncProductAndVideoDataToPage };
